/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

// import AnimatedCursor from 'react-animated-cursor';
import '../../static/assets/styles/layout.css';

const AnimatedCursor = loadable(() => import('react-animated-cursor'), {
  ssr: false,
});
const Header = loadable(() => import('./header'));
// import Header from './header';
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      console.log(window.innerWidth);
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}
const Layout = ({ children, user }: { children?: any; user?: any }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);
  // const [height, setMaxHeight] = React.useState(0);
  // React.useEffect(() => {
  //   const maxHeight = () => {
  //     const header = document.querySelector('header')?.offsetHeight;
  //     const footer = document.querySelector('footer')?.offsetHeight;
  //     const wrap = window.screen.width <= 450 ? 150 : 75;
  //     return window.screen.height - wrap - header - footer;
  //   };
  //   // setMaxHeight(maxHeight());
  // });
  return (
    <div className="flex flex-col h-screen w-full">
      <AnimatedCursor
        innerSize={12}
        outerSize={32}
        color="0, 0, 0"
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={1.5}
        clickables={[
          'a',
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'textarea',
          'button',
          '.link',
          '.pro-item',
        ]}
        outerStyle={{
          border: '1px solid #000',
          background: 'transparent',
        }}
        innerStyle={{}}
      />
      <Header
        // siteTitle={data.site.siteMetadata?.title || `Title`}
        user={user}
      />
      <main className="w-full ">
        <div className="h-full">{children}</div>
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
